import { useEffect, useState } from "react";
import BlogCard from "./BlogCard"
function BlogPage() {
 
    const [blog,setBlog]=useState([])
    const [lastimported,setLastId]=useState(0)
    const [isDataEnd,dataEnd]=useState(false)
  
  async function loadMore(){
    const formData = new FormData();
        formData.append("lastimported", lastimported);
    await fetch("https://blog.orcapixel.com/fetchBlog.php", {
      method: "POST",
      body: formData,
    }).then((result)=>{
      
        result.json().then((resp)=>{
          if(resp.length != 0 || resp.length > 2){
                setBlog([...blog,...resp])
                setLastId(resp[resp.length-1].blogId)
            }
            else{
              dataEnd(true)
            }  
        })
      })
    }
    useEffect(()=>{
      if(lastimported==0){
        loadMore()
      }
    })
    return(
        <>
        {blog.map((i,x)=>{
            return(
             <BlogCard key={x} blogx={i}/>
          )
         
        })}
        <div className="flex justify-center items-center">
        {
          
          !isDataEnd?   <button className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 center " onClick={loadMore}>load more</button>:<h3 className="text-white bg-gradient-to-br from-yellow-600 to-yellow-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 center ">NO MORE DATA</h3>
        }
        </div>
        </>
    )
}
export default BlogPage