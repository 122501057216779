import {NavLink} from 'react-router-dom';

function BlogCard(BlogInfo) {
  var blogId=`/blog/${BlogInfo.blogx.blogId}`
    return(
        
            <div className=" rounded-3xl flex flex-col justify-center items-end p-5 h-fit bg-gradient-to-br from-slate-200 to-slate-400">
                <div className='w-full h-44 '>
                <NavLink className="underline text-center" to={blogId}><img src={BlogInfo.blogx.blogImg} className='object-cover w-full h-full rounded-xl' alt="" /></NavLink>
                  
                </div>
                <div className="text-slate-900 w-full p-5 h-fit rounded-2xl">
                <h1 className="font-semibold   uppercase mb-2 ">{BlogInfo.blogx.blogDate.split(" ")[0]}</h1> 
                <h1 className="font-black  uppercase text-2xl">{BlogInfo.blogx.blogName}</h1>
                <h1 className="italic font-bold mb-2">-{BlogInfo.blogx.blogAuthor}</h1>
                <p>{BlogInfo.blogx.blog.length <= 60 ? BlogInfo.blogx.blog : BlogInfo.blogx.blog.substring(0, 60)} . . .</p>
                <NavLink className="underline text-center" to={blogId}>Read Blog</NavLink>
                </div>
            </div>
    )
}
export default BlogCard