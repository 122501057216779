import logo from "../logo.svg"
import { NavLink} from 'react-router-dom';

function Navi() {
    return(
        <nav className="bg-transparent backdrop-blur-2xl fixed top-0 w-full flex justify-center items-center p-2 h-12">
            <NavLink className=" h-full" to="/"><img src={logo} alt="orcapixellogo" className="h-full" /></NavLink>
        </nav>
    )
}
export default Navi