import { useEffect, useState } from 'react';

import { useParams, useLocation} from 'react-router-dom';
import BlogCard from "./BlogCard"
function Blog() {
        const location = useLocation()
        var blogid=useParams().blogID
        const [blog,setBlog]=useState([])
        const [nextblogs,setnextblogs]=useState([])
        const [lastimported,setLastId]=useState(0)
        const [isdata,dataEnd]=useState(false)
        
      async function loaddata(){
        const formData = new FormData();
            formData.append("blogID", blogid);
        await fetch("https://blog.orcapixel.com/fetchSingle.php", {
          method: "POST",
          body: formData,
        }).then((result)=>{
            result.json().then((resp)=>{
              if(resp.length != 0)
                    setBlog(resp)
                    dataEnd(true)
                    setLastId(resp[0].blogId)
            })
          })
        }
        async function nextload(){
                const formData = new FormData();
                    formData.append("lastimported", lastimported);
                await fetch("https://blog.orcapixel.com/fetchBlog.php", {
                  method: "POST",
                  body: formData,
                }).then((resultnext)=>{
                  
                    resultnext.json().then((response)=>{
                      if(response.length != 0){
                            setnextblogs(response)
                        }
                        else{
                          dataEnd(true)
                        }  
                    })
                  })
                }


        useEffect(()=>{
            loaddata()
        
        },[])
        useEffect(()=>{
            nextload()
        
        },[lastimported])
        useEffect(()=>{
            loaddata()
            nextload()
        
        },[location])
        
      return(
        <>
        {isdata ?  <section className="min-h-screen pt-20 px-4 sm:px-14 bg-slate-100">
        <div id="headerDiv" className="grid gap-6 grid-cols-1 sm:grid-cols-2 text-slate-900">
        
                       <div><img className="object-cover w-full h-full sm:h-80 rounded-xl" src={blog[0].blogImg} alt="" /></div>
                       <div className='p-5'><span className='font-semibold   uppercase mb-2'>{blog[0].blogDate.split(" ")[0]} </span><h1 className='font-black  uppercase text-3xl mb-4'>{blog[0].blogName} </h1><h2 className='italic'>Written By <span className='font-bold'>{blog[0].blogAuthor}</span></h2></div>
                       <div className='col-span-1 sm:col-span-2 p-5'><p className='indent-11'>{blog[0].blog}</p></div>
        </div>
    </section> : null}    
    <section className="min-h-screen pt-20 px-4 sm:px-14 bg-slate-100">
        <h1 className="font-black text-slate-700  uppercase text-7xl mb-9">Read Next</h1>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-3 min-h-screen">
        {nextblogs.map((i,x)=>{
            return(
             <BlogCard key={x} blogx={i}/>
          )
         
        })}
      </div>
        </section>
    
        </>      
      )
  }
  export default Blog