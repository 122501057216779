import BlogPage from "./components/BlogPage"
function App() {

  
  return (
    <div className="App">
     
   
      <section className="min-h-screen pt-20 px-4 sm:px-14 bg-slate-100">
        <h1 className="font-black text-slate-700  uppercase text-7xl mb-9">Blog page</h1>
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-3 min-h-screen">
      <BlogPage></BlogPage>
      </div>
        </section>
    </div>
 
  );
}

export default App;
